import { createTheme, responsiveFontSizes } from "@mui/material/styles"
import { seamColors } from "./seamColors"

const seamFonts = [
  '"Source Sans 3"',
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  "system-ui",
].join(",")

const themeData = createTheme({
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: { xs: 0, sm: 640, md: 768, lg: 1024, xl: 1280 },
  },
  mixins: {
    toolbar: {
      minHeight: 56,
      "@media (min-width:0px) and (orientation: landscape)": {
        minHeight: 48,
      },
      "@media (min-width:600px)": { minHeight: 64 },
    },
  },
  shadows: [
    "none",
    "0px 1px 4px 0px rgba(0, 0, 0, 0.12)", // profile loading state
    "0px 3px 8px -1px rgb(50 50 71 / 5%)", // profile list
    "drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))", // profile list
    "0px 10px 16px 0px #0B182C14, 0px 0px 1px 0px #0C1A4B1A", // large
    // The following is to placate Material UI which uses 24 levels of shadows
    // which are generated programmatically. We don't use them, but we need to
    // define them to avoid a console warning.
    "0px 10px 16px 0px #0B182C14, 0px 0px 1px 0px #0C1A4B1A", // large
    "0px 10px 16px 0px #0B182C14, 0px 0px 1px 0px #0C1A4B1A", // large
    "0px 10px 16px 0px #0B182C14, 0px 0px 1px 0px #0C1A4B1A", // large
    "0px 10px 16px 0px #0B182C14, 0px 0px 1px 0px #0C1A4B1A", // large
    "0px 10px 16px 0px #0B182C14, 0px 0px 1px 0px #0C1A4B1A", // large
    "0px 10px 16px 0px #0B182C14, 0px 0px 1px 0px #0C1A4B1A", // large
    "0px 10px 16px 0px #0B182C14, 0px 0px 1px 0px #0C1A4B1A", // large
    "0px 10px 16px 0px #0B182C14, 0px 0px 1px 0px #0C1A4B1A", // large
    "0px 10px 16px 0px #0B182C14, 0px 0px 1px 0px #0C1A4B1A", // large
    "0px 10px 16px 0px #0B182C14, 0px 0px 1px 0px #0C1A4B1A", // large
    "0px 10px 16px 0px #0B182C14, 0px 0px 1px 0px #0C1A4B1A", // large
    "0px 10px 16px 0px #0B182C14, 0px 0px 1px 0px #0C1A4B1A", // large
    "0px 10px 16px 0px #0B182C14, 0px 0px 1px 0px #0C1A4B1A", // large
    "0px 10px 16px 0px #0B182C14, 0px 0px 1px 0px #0C1A4B1A", // large
    "0px 10px 16px 0px #0B182C14, 0px 0px 1px 0px #0C1A4B1A", // large
    "0px 10px 16px 0px #0B182C14, 0px 0px 1px 0px #0C1A4B1A", // large
    "0px 10px 16px 0px #0B182C14, 0px 0px 1px 0px #0C1A4B1A", // large
    "0px 10px 16px 0px #0B182C14, 0px 0px 1px 0px #0C1A4B1A", // large
    "0px 10px 16px 0px #0B182C14, 0px 0px 1px 0px #0C1A4B1A", // large
    "0px 10px 16px 0px #0B182C14, 0px 0px 1px 0px #0C1A4B1A", // large
  ],
  direction: "ltr",
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          margin: "8px 0",
        },
        message: {
          width: "80%",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: seamColors.gray.faint,
          color: seamColors.black,
        },
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        endAdornment: {
          display: "none",
          right: 8,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          boxShadow: "none",
          "&:hover": {
            backgroundColor: seamColors.black,
            boxShadow: "none",
          },
        },
        containedPrimary: {
          backgroundColor: seamColors.black,
          fontWeight: 600,
          boxShadow: "none",
          "&:hover": {
            backgroundColor: seamColors.primary.dark,
            boxShadow: "none",
          },
        },
        containedSecondary: {
          backgroundColor: seamColors.gray.light,
          boxShadow: "none",
          color: seamColors.black,
          "&:hover": {
            backgroundColor: seamColors.gray.darkest,
            color: seamColors.gray.faint,
            boxShadow: "none",
          },
        },
        containedSizeLarge: {
          fontSize: "inherit",
        },
        root: {
          fontWeight: 400,
          height: "48px",
          padding: "0 24px",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          "@media (min-width: 1280px)": {
            maxWidth: "100%",
          },
        },
        root: {
          paddingTop: 16,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          boxShadow:
            "0px 0px 1px rgba(12, 26, 75, 0.1), 0px 10px 16px rgba(20, 37, 63, 0.06)",
          margin: 0,
          overflowX: "hidden",
          padding: "24px 40px",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "8px 0",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "8px 0",
          "&:first-of-type": {
            paddingBottom: 0,
            paddingTop: 8,
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: "8px 0 12px 0",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: seamColors.gray.lightest,
          "&:hover": {
            backgroundColor: seamColors.gray.lightest,
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: "0 0 8px 0",
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        colorAction: {
          color: seamColors.black,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        sizeSmall: {
          padding: "8px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          minHeight: 48,
          paddingLeft: 16,
        },
        input: {
          "&:focus": {
            backgroundColor: seamColors.transparent,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: 0,
        },
        root: {
          borderColor: seamColors.gray.light,
          paddingLeft: 12,
          paddingRight: 12,
          backgroundColor: seamColors.gray.faint,
          borderRadius: 6,
          "&:before": {
            borderBottom: "none",
          },
          "&:after": {
            borderBottom: "none",
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          "&:hover": {
            cursor: "pointer",
          },
          fontWeight: 500,
        },
      },
      defaultProps: {
        underline: "none",
      },
    },
    MuiList: {
      styleOverrides: {
        padding: {
          paddingBottom: 0,
          paddingTop: 0,
        },
        root: {
          width: "100%",
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          color: seamColors.gray.dark,
          borderLeft: "#fff 4px solid",
          "&.Mui-selected": {
            backgroundColor: seamColors.white,
            color: seamColors.primary.DEFAULT,
            borderLeft: `${seamColors.primary.DEFAULT} 4px solid`,
            "&:hover": {
              backgroundColor: seamColors.white,
              color: seamColors.primary.DEFAULT,
            },
          },
          "&.Mui-focusVisible": {
            backgroundColor: seamColors.white,
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: 500,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderBottom: "#eee 1px solid",
          borderLeft: "none",
          paddingBottom: 16,
          paddingTop: 16,
          "&.Mui-selected": {
            borderLeft: "none",
          },
          "&:last-child": {
            borderBottom: "none",
          },
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
            borderLeft: "none",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: "0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
          filter: "drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          border: "#eee 1px solid",
          boxShadow:
            "0px 0px 1px rgba(12, 26, 75, 0.1), 0px 4px 20px -2px rgba(50, 50, 71, 0.08)",
          padding: "15px",
          maxWidth: "500px",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: seamColors.gray.dark,
          textTransform: "none",
          minWidth: 72,
          marginRight: 24,
          fontWeight: 600,
          "&:hover": {
            color: seamColors.black,
            opacity: 1,
          },
          "&.Mui-selected": {
            color: seamColors.black,
            fontWeight: 500,
          },
          "@media (min-width: 600px)": {
            minWidth: 72,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: seamColors.primary.DEFAULT,
          minWidth: 72,
        },
        root: {
          textAlign: "center",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          borderColor: seamColors.gray.light,
          color: seamColors.gray.dark,
          fontWeight: 400,
          padding: "24px",
        },
        head: {
          borderColor: seamColors.gray.light,
          color: seamColors.black,
          fontWeight: 600,
          padding: "24px",
        },
        root: {
          textAlign: "left",
        },
        stickyHeader: {
          backgroundColor: seamColors.white,
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        actions: {
          flexGrow: 1,
          textAlign: "end",
        },
        input: {
          fontSize: "0.875rem",
        },
        spacer: {
          flex: 0,
        },
        root: {
          overflow: "visible",
        },
        toolbar: {
          borderTop: "#eee solid 1px",
          display: "flex",
          padding: "24px",
          paddingRight: "24px",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        regular: {
          "@media (min-width: 600px)": {
            minHeight: 80,
          },
        },
      },
    },
  },
  transitions: {
    easing: {
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
    duration: {
      standard: 300,
      short: 250,
      enteringScreen: 225,
      shorter: 200,
      leavingScreen: 195,
      shortest: 150,
      complex: 375,
    },
  },
  typography: {
    fontFamily: seamFonts,
    fontSize: 16,
    htmlFontSize: 14,
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 500,
    subtitle1: {
      fontFamily: seamFonts,
      lineHeight: 1.5,
      fontSize: "1.143rem",
      fontWeight: 500,
    },
    subtitle2: {
      fontFamily: seamFonts,
      lineHeight: 1.5,
      fontSize: "1rem",
      fontWeight: 500,
    },
    body1: {
      fontFamily: seamFonts,
      lineHeight: 1.5,
      fontSize: "0.875rem",
      fontWeight: 500,
    },
    body2: {
      fontFamily: seamFonts,
      lineHeight: 1.5,
      fontSize: "0.875rem",
      fontWeight: 600,
    },
    button: {
      textTransform: "none",
      fontFamily: seamFonts,
      fontSize: "0.925rem",
      fontWeight: 500,
    },
    caption: {
      fontFamily: seamFonts,
      lineHeight: 1.5,
      fontSize: "0.75rem",
      fontWeight: 500,
    },
  },
  zIndex: {
    modal: 1300,
    snackbar: 1400,
    drawer: 1200,
    appBar: 1100,
    mobileStepper: 1000,
    tooltip: 1500,
  },
  shape: { borderRadius: 6 },
  spacing: 8,
  palette: {
    tonalOffset: 0.2,
    background: { paper: seamColors.white, default: seamColors.gray.faint },
    contrastThreshold: 3,
    grey: {
      "50": seamColors.white,
      "100": seamColors.gray.faint,
      "200": seamColors.gray.lightest,
      "300": seamColors.gray.light,
      "400": seamColors.gray.DEFAULT,
      "500": seamColors.gray.dark,
      "600": seamColors.gray.dark,
      "700": seamColors.gray.darkest,
      "800": seamColors.black,
      "900": seamColors.black,
      A700: "#616161",
      A100: "#d5d5d5",
      A400: "#303030",
      A200: "#aaaaaa",
    },
    text: {
      primary: seamColors.black,
      secondary: seamColors.gray.dark,
      disabled: "rgba(0, 0, 0, 0.5)",
    },
    divider: seamColors.gray.light,
    secondary: {
      light: seamColors.secondary.faint,
      main: seamColors.secondary.DEFAULT,
      dark: seamColors.secondary.dark,
      contrastText: seamColors.white,
    },
    common: { black: seamColors.black, white: seamColors.white },
    error: {
      light: seamColors.error.faint,
      main: seamColors.error.DEFAULT,
      dark: seamColors.error.dark,
      contrastText: seamColors.white,
    },
    success: {
      light: seamColors.secondary.faint,
      main: seamColors.secondary.DEFAULT,
      dark: seamColors.secondary.dark,
      contrastText: seamColors.secondary.black,
    },
    warning: {
      light: seamColors.warning.faint,
      main: seamColors.warning.DEFAULT,
      dark: seamColors.warning.dark,
      contrastText: seamColors.black,
    },
    mode: "light",
    action: {
      hoverOpacity: 0.04,
      hover: "rgba(0, 0, 0, 0.04)",
      selected: seamColors.black,
      disabledBackground: "rgba(0, 0, 0, 0.1)",
      disabled: "rgba(0, 0, 0, 0.3)",
      active: seamColors.gray.dark,
    },
    primary: {
      light: seamColors.primary.faint,
      main: seamColors.primary.DEFAULT,
      dark: seamColors.primary.dark,
      contrastText: seamColors.white,
    },
  },
})

export const theme = responsiveFontSizes(themeData)
