import "./global.css"
import React, {
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useEffect,
} from "react"
import dynamic from "next/dynamic"
import CssBaseline from "@mui/material/CssBaseline"
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles"
import { CookiesProvider } from "react-cookie"
import { theme } from "../src/core/theme"
import { QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { ClerkProvider } from "@clerk/nextjs"
import { queryClient } from "../src/api-hooks/query-client"
import { Toaster } from "react-hot-toast"
import { IntercomProvider } from "react-use-intercom"
import "./app.css"
import "react-phone-input-2/lib/style.css"
import { RegisterUserWithIntercom } from "account/components/RegisterUserWithIntercom"
import { NextPage } from "next"
import Head from "next/head"
import { init as initFullStory } from "@fullstory/browser"

import { AppProps } from "next/app"

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

function defaultGetLayout(page: ReactElement) {
  return page
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

// The following are used to disable SSR around the App component's children.
// This is necessary because the app as a whole does not support SSR, but
// Next assumes that all pages support SSR by default.
const Wrapper = ({ children }: PropsWithChildren<any>) => <>{children}</>
const NoSSR = dynamic(async () => Wrapper, { ssr: false })

function App({ Component, pageProps }: AppPropsWithLayout) {
  useEffect(() => {
    const orgId = process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID
    if (!orgId) {
      console.warn(
        "Cannot initialize FullStory, NEXT_PUBLIC_FULLSTORY_ORG_ID not set"
      )
      return
    }
    initFullStory({ orgId })
  }, [])
  if (!process.env.NEXT_PUBLIC_API_HOST) {
    return <p style={{ color: "red" }}>NEXT_PUBLIC_API_HOST not set</p>
  }

  const getLayout = Component.getLayout ?? defaultGetLayout

  return (
    <StyledEngineProvider injectFirst>
      <ClerkProvider
        // TODO manually redirect, Clerk is causing redirect loops
        navigate={async () => {
          // completely disable clerk redirects, because they cause redirect bugs
          // console.log({ to })
        }}
      >
        <QueryClientProvider client={queryClient}>
          <CookiesProvider>
            <IntercomProvider appId={process.env.NEXT_PUBLIC_INTERCOM_APP_ID!}>
              <ThemeProvider theme={theme}>
                <Head>
                  <link rel="icon" href="/favicon-light.ico" />
                </Head>
                <CssBaseline />
                <Toaster containerStyle={{ zIndex: 999_999 }} />
                <RegisterUserWithIntercom />
                <NoSSR>{getLayout(<Component {...pageProps} />)}</NoSSR>
              </ThemeProvider>
            </IntercomProvider>
          </CookiesProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ClerkProvider>
    </StyledEngineProvider>
  )
}

export default App
